.reset-elements {
  .order-step-wrap {
    width: 100%;
  }

  .order-step-circle {
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    text-align: center !important;

    &.color-green {
      background-color: #0ba75137;
      border: 1px solid #0ba750;
      color: #0ba750;
    }
    &.color-blue {
      background-color: #e6f1fd;
      border: 1px solid #0071eb;
      color: rgba(0, 113, 235, 1);
    }
    &.color-white {
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: rgba(189, 189, 189, 1);
    }
  }

  .order-step-line {
    width: 96% !important;
    margin: 0 10px;
    margin-top: 12px;

    display: block !important;

    &.color-green {
      background-color: #0ba75137;
      border-bottom: 2px solid #0ba750;
      color: #0ba750;
    }
    &.color-blue {
      background-color: #e6f1fd;
      border-bottom: 2px solid #0071eb;
      color: rgba(0, 113, 235, 1);
    }
    &.color-white {
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
      color: rgba(189, 189, 189, 1);
    }
  }

  .order-step-list-wrap {
    width: 100%;
  }

  .steps-label {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.001px;
    margin-left: -15px;
    &.last-step {
      margin-left: -55px;
    }
    &.first-step {
      margin-left: 20px;
    }
    &.color-green {
      color: #0ba750;
    }

    &.color-blue {
      color: rgba(0, 113, 235, 1);
    }

    &.color-white {
      color: rgba(189, 189, 189, 1);
    }
  }

  .order-list-card {
    background: white;
    padding-bottom: 80px;
    min-height: 100vh;
    box-shadow: none;
    .bi-arrow-repeat,
    .bi-list,
    .bi-grid,
    .select__control,
    .select__indicators,
    tr {
      cursor: pointer !important;
    }
    .card-body {
      padding: 0px !important;
    }
    .table tbody tr td {
      border-bottom: none !important;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .filter-header {
    .form-group .input-group {
      min-width: 0px !important;
      height: 32px;
    }
    .reset-btn {
      height: 32px;
    }
    .select__control {
      height: 32px;
    }
  }
}
