.reset-elements {
  .react-bootstrap-table {
    .table {
      margin-bottom: 8px !important;
      .sortable {
        font-size: 10px;
        font-family: "Inter";
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #8c8c8c;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.02) 0%,
            rgba(0, 0, 0, 0.02) 100%
          ),
          #fff;
      }
    }
  }

  .order_start-custom-td:nth-child(4) {
    font-size: 12px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .state-button {
    box-sizing: border-box;
    border-radius: 0px !important;
    width: 170px !important;
    font-size: 14px !important;
    font-family: "Inter" !important;
    line-height: 20px !important;
    letter-spacing: -0.1px !important;
    text-align: center !important;
    color: #f5f5f5 !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
    }
  }

  // button colors for the order stages
  .order-submitted {
    background: #54a0f2 !important;
    border-color: #54a0f2 !important;
  }
  .sourcing-candidates {
    background: #fea75c !important;
    border-color: #fea75c !important;
  }
  .order-in-progress {
    background: #5cc48a !important;
    border-color: #5cc48a !important;
  }

  .term-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .term-text {
    color: rgba(140, 140, 140, 1);
    text-align: center;
    font-size: 14px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: -0.1px;
  }
  .duration-wrap {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .icon-duration {
    margin-right: 4px;
    font-size: 16px;
    color: #000000 !important;
    opacity: 0.45 !important;
  }
  .duration-text {
    font-size: 12px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 0.001px;
  }
  .info-text {
    font-size: 12px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 0.001px;
    color: #121212;
  }
  .sub-infor-text {
    font-size: 11px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 0.001px;
    color: #616161;
  }
  p {
    margin-bottom: 0px !important;
  }
  .order-start-text {
    font-size: 12px;
    font-family: "Inter";
    line-height: 18px;
    letter-spacing: 2px;
    color: #121212;
    font-weight: 400;
  }
  .sub-order-start-text {
    font-size: 11px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 0.001px;
    color: #8c8c8c;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
  }

  .client-text {
    font-size: 14px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 0.001px;
    color: #ce1e7d;
    font-weight: 500;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .order-text {
    font-size: 14px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 0.001px;
    color: #121212;
    font-weight: 400;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sub-order-text {
    font-size: 11px;
    font-family: "Inter";
    line-height: 18px;
    letter-spacing: 0.001px;
    color: #8c8c8c;
  }
  .order-wrap {
    border-left: 2px solid #ce1e7d;
    padding-left: 8px;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .order-wrap {
    &.pending {
      border-left: 2px solid #570d35 !important;
      padding-left: 8px;
    }
  }
  .icon-wrap {
    margin-top: 14px;
    float: right;
  }

  .submitted {
    background-color: #121212;
  }
  .time-spend-text {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #212121;
  }
  .sub-time-spend-text {
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 200% */
    letter-spacing: 0.001px;
    color: #8c8c8c;
  }
  .paid-text {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.001px;
    margin-top: -10px;
    color: #212121;
  }
  .sub-paid-text {
    font-family: "JetBrains Mono";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 200% */
    letter-spacing: 0.001px;
    color: #8c8c8c;
  }

  .order-custom-td {
    width: 28% !important;
    padding-right: 10px;
  }
  .client-custom-td {
    width: 20% !important;
    padding-right: 10px;
  }

  .info-custom-td-shedule {
    padding: 0px !important;
  }
  .order-details-icon {
    float: right;
  }

  .card-body-shedule {
    padding: 0px 16px 0px 16px;
  }

  .table tbody tr td {
    padding: 0px !important;
  }

  .rotate-icon {
    font-size: 16px;
    line-height: 22px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .chevron-right {
    transform: rotate(270deg) translate(0);
    transform-origin: center center;
    background: #b3b2b2;
  }

  .chevron-down {
    transform-origin: center center;
    transform: rotate(360deg) translate(0);
    transition: transform 0.4s ease-in-out;
    border: 0.5px solid #b3b2b2;
  }

  .table-content {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.02) 0%,
        rgba(0, 0, 0, 0.02) 100%
      ),
      #fff;
    vertical-align: middle;
    margin: 8px !important;
    margin: 8px !important;
  }
}
