.reset-elements {
  .order-collapse-tag {
    color: #212121 !important;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: -0.008px;
  }

  .order-collapse-date {
    color: #8c8c8c !important;
    font-size: 16px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 0.001px;
    position: relative;
    min-height: 100vh;
  }
  .collaps-style {
    margin-bottom: 13px;
    border-radius: 3px;
    padding: 14px;
    border-left: 2px solid #ccc !important;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.02) 0%,
        rgba(0, 0, 0, 0.02) 100%
      ),
      #fff;
    i {
      cursor: pointer;
    }
  }
}
