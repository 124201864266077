//btn-variables
$btn-xs-padding:            2px 8px;
$btn-fs:                    12px;

//link-variables
$link-fs:                   14px;
$link-lg-fs:                16px;
$link-lg-sm:                12px;
$link-primary-color:        #0071EB !important;

//avatar-variables
$avatar-border-color:       #8C8C8C;
