h1,
h2,
h3 {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 150%;
}

h4,
h5,
h6 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  // text-transform: uppercase;
}

h1 {
  font-size: 96px;
  line-height: 152px;
  letter-spacing: 1.5px;
}

h2 {
  font-size: 64px;
  line-height: 66px;
}

h3 {
  font-size: 48px;
  line-height: 60px;
}

h4 {
  font-size: 40px;
  line-height: 54px;
}

h5 {
  font-size: 36px;
  line-height: 48px;
}

h6 {
  font-size: 30px;
  line-height: 42px;
}

p,
span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.304px;
  margin-bottom: 8px;

  &.lead {
    font-family: "Lexend" !important;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.00304em;
  }

  &.lg {
    font-family: "Lexend" !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  &.md {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.304px;
  }

  &.sm {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.304px;
  }

  &.xs {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.001px;
  }

  &.overline {
    font-family: "Inter";
    font-size: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8c8c8c;
  }

  &.hint-text {
    font-family: "Outfit" !important;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    opacity: 0.5;
  }
  &.mono-xs{
    font-family: 'JetBrains Mono';
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.001px;
  }
  &.mono-sm{
    font-family: 'JetBrains Mono';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.001px;
  }  
  &.mono-md{
    font-family: 'JetBrains Mono';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
  }
}

.height-lg {
  line-height: 200% !important;
}

.fs-06 {
  font-size: 6px !important;
  letter-spacing: 0.0180093em;
  line-height: 15px;
}

.fs-08 {
  font-size: 8px !important;
  letter-spacing: 0.0180093em;
  line-height: 15px;
}

.fs-09 {
  font-size: 9px !important;
  letter-spacing: 0.0180093em;
  line-height: 15px;
}

.fs-10 {
  font-size: 10px !important;
  letter-spacing: 0.0180093em;
  line-height: 15px;
}

.fs-11 {
  font-size: 10.5px !important;
  letter-spacing: 0.0128359em;
  line-height: 16px;
}

.fs-12 {
  font-size: 12px !important;
  letter-spacing: 0.00849077em;
  line-height: 18px;
}

.fs-13 {
  font-size: 13px !important;
  letter-spacing: 0.00484144em;
  line-height: 19px;
}

.fs-14 {
  font-size: 14px !important;
  letter-spacing: 0.00177646em;
  line-height: 22px;
}

.fs-15 {
  font-size: 15px !important;
  letter-spacing: -0.000797757em;
  line-height: 23px;
}

.fs-16 {
  font-size: 16px !important;
  letter-spacing: -0.00295978em;
  line-height: 24px;
}

.fs-17 {
  font-size: 17px !important;
  letter-spacing: -0.0127756em;
  line-height: 24px;
}

.fs-18 {
  font-size: 18px !important;
  letter-spacing: -0.0143007em;
  line-height: 25px;
}
.fs-20{
  font-size: 20px !important;
  letter-spacing: -0.0143007em;
  line-height: 25px;
}
.fs-24 {
  font-size: 24px !important;
  line-height: 32px;
}
.fs-34 {
  font-size: 34px !important;
  line-height: 40px;
}

.text-opac-70 {
  opacity: 0.7;
}

.text-opac-80 {
  opacity: 0.8;
}

.text-opac-60 {
  opacity: 0.6;
}

.text-opac-90 {
  opacity: 0.9;
}

.text-opac-56 {
  opacity: 0.56;
}

.text-opac-86 {
  opacity: 0.86;
}

.text-align-end {
  text-align: end;
}

.menu {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: calc(96px * 0.9);
    line-height: calc(152px * 0.9);
  }

  h2 {
    font-size: calc(64px * 0.9);
    line-height: calc(66px * 0.9);
  }

  h3 {
    font-size: calc(48px * 0.9);
    line-height: calc(60px * 0.9);
  }

  h4 {
    font-size: calc(40px * 0.9);
    line-height: calc(54px * 0.9);
  }

  h5 {
    font-size: calc(36px * 0.9);
    line-height: calc(48px * 0.9);
  }

  h6 {
    font-size: calc(30px * 0.9);
    line-height: calc(42px * 0.9);
  }

  p {
    font-size: calc(20px * 0.9);
    line-height: calc(29px * 0.9);

    &.lead {
      font-size: calc(22px * 0.9);
      line-height: calc(32px * 0.9);
    }

    &.lg {
      font-size: calc(20px * 0.9);
      line-height: calc(28px * 0.9);
    }

    &.md {
      font-size: calc(18px * 0.9);
      line-height: calc(22px * 0.9);
    }

    &.sm {
      font-size: calc(16px * 0.9);
      line-height: calc(22px * 0.9);
    }

    &.overline {
      font-size: 19px;
      line-height: 30px;
    }
  
  }
}

.text-overflow {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-muted {
  color: #8c8c8c !important;
}

.text-blue {
  color: #0071eb !important;
}

.text-yellow {
  color: #ffbd00 !important;
}
