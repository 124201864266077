.reset-elements {
  .avatar {
    border-radius: 50%;
    border: 1px solid $avatar-border-color !important;
    vertical-align: middle;
    position: relative;
    .avatar-line {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 0;
      border-style: solid;
      padding: 0px;
      z-index: 0;
      position: absolute;
      left: 18px;
      height: 100%;
    }
    .avatar-active {
      content: "";
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background: green;
      position: absolute;
      top: -10px;
      border: 1px solid white;
      top: -4px;
      right: -3px;
    }
  }

  .avatar-group {
    position: relative;
    background: #f0f0f0;
    border: none;
    border-radius: 50px;
    width: fit-content;
    padding: 3px;
    width: 97px;
    height: 38px;
    .avatar {
      position: absolute;
      border: 2px solid #fff !important;
      border-radius: 100px;
      width: 32px;
      height: 32px;
      &:nth-child(2) {
        left: 17px;
      }
      &:nth-child(3) {
        left: 32px;
      }
      &:nth-child(4) {
        left: 47px;
      }
      &:nth-child(5) {
        left: 62px;
      }
      &.avatar-num {
        background: #ce1e7d;
        color: white;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 200% */
        text-transform: uppercase;
      }
    }
  }
}
