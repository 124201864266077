.reset-elements {
  .order-activity {
    position: relative;
    .activity-line {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 0;
      border-style: solid;
      padding: 0px;
      z-index: 0;
      position: absolute;
      left: 19.5px;
      height: calc(100% - 15px);
      top: 45px;
    }
  }

  .expand-cell-header,
  .expand-cell {
    width: 20px;
    cursor: pointer;
  }
  .expand-cell-header {
    content-visibility: hidden;
  }
  .text-hint {
    color: #8c8c8c;
  }
  .expanded-area {
    margin: 8px 0px 12px 69px;
    padding: 0px 12px;
    background: #f0f0f0;
    border-radius: 2px;
    .shift-card {
      background: transparent !important;
      .card-header {
        background: transparent !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding: 6px;
      }
      .card-body {
        padding: 6px;
      }
    }
  }

  .assigned-workers {
    .data-row {
      padding: 8px 0px;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
  .worker-list {
    thead {
      display: none !important;
    }
    tr {
      border: transparent !important;
    }
  }
  .application-table {
    position: relative;
    thead {
      display: table-header-group !important;
    }
    tr {
      border: none !important;
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
    .expanded-btn {
      border: none !important;
      position: absolute;
      right: 10px;
    }
  }

  .badge {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    padding: 0px 4px;
    &.badge-light {
      background-color: rgba(128, 128, 128, 0.14);
      color: black;
    }
    &.badge-primary {
      background-color: #ce1e7d;
      color: white;
    }
    &.badge-success {
      background-color: #0ba750;
      color: white;
    }
  }

  .add-btn {
    height: 24px;
    width: 24px;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0px 4px;
    cursor: pointer;
  }
}
