.reset-elements {
  .header-wrap {
    margin-left: 28px;
  }

  .header-title {
    color: #212121;
    font-size: 21px;
    font-family: "Inter";
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.017px;
  }

  .header-temp {
    margin-top: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
