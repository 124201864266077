.reset-elements {
  .link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: $link-fs;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.1px;
    cursor: pointer;
    &.link-lg {
      font-size: $link-lg-fs;
      line-height: 24px; /* 150% */
      letter-spacing: -0.18px;
    }
    &.link-sm {
      font-size: $link-lg-sm;
      line-height: 16px;
    }
    &.link-primary {
      color: $link-primary-color;
    }
  }
}
