.reset-elements {
  .select-option {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.87) !important;
    text-overflow: ellipsis;
    font-size: 14px !important;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: -0.1px;
  }

  .form-select {
    padding: 6px 0px 6px 14px !important;
    border-radius: 3px !important;
    width: 181px !important;
    font-size: 14px !important;
    font-family: "Inter" !important;
    line-height: 20px !important;
    letter-spacing: -0.1px !important;
    color: #000000 !important;
    opacity: 0.87 !important;
  }

  .DatePicker-Input {
    font-size: 14px !important;
    text-align: left !important;
    font-family: "Inter" !important;
    line-height: 20px !important;
    letter-spacing: -0.1px !important;
    color: #000000 !important;
    opacity: 0.87 !important;
    padding: 6px 0px 6px 14px !important;
    border-radius: 3px !important;
  }

  .datepicker-input-custom {
    position: relative;
  }

  .add-on-icon {
    position: absolute;
    right: 20px;
    top: 6px;
    width: 12px;
    height: 12px;
    z-index: 1000;

    .material-icons {
      font-size: 16px;
      color: #d6cbcb;
    }
  }

  .reset-btn {
    font-size: 14px !important;
    font-family: "Inter" !important;
    color: #616161 !important;
    line-height: 20px !important;
    width: auto !important;
    border-radius: 3px !important;
  }

  .form-control {
    width: 250px !important;
    padding: 6px 5px 6px 2px !important;
    border-radius: 3px !important;
    font-size: 14px !important;
    margin: 0px !important;
    font-family: "Inter" !important;
    line-height: 20px !important;
    letter-spacing: -0.1px !important;
    border-radius: 0px 3px 3px 0px !important;
    border-left: none !important;

    &:focus {
      box-shadow: none !important;
      border-color: rgb(222, 226, 230) !important;
    }
  }

  .input-group-prepend {
    padding: 3px 8px;
    border-left: 1px solid rgb(222, 226, 230);
    border-top: 1px solid rgb(222, 226, 230);
    border-bottom: 1px solid rgb(222, 226, 230);
    border-radius: 3px 0px 0px 3px;
  }

  .input-group-text {
    background-color: #fff !important;
    padding: 6px 0px 6px 10px !important;
    border-radius: 3px 0px 0px 3px !important;
    line-height: 20px !important;
  }

  .search-icon {
    color: #000000 !important;
    opacity: 0.45 !important;
  }

  .all-clients-select,
  .all-job-types {
    width: auto;
    min-width: 200px;
    max-width: auto;
    flex-wrap: nowrap;
    min-height: 0 !important;

    .css-13cymwt-control {
      border-radius: 3px !important;
      min-height: 0 !important;
      height: auto;
    }

    .css-1xc3v61-indicatorContainer {
      padding: 2px !important;
    }

    .css-qbdosj-Input {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .css-wsp0cs-MultiValueGeneric {
      padding: 1px !important;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__clear-indicator {
      padding: 0px !important;
    }
  }

  .filter-name-tag {
    color: rgba(0, 0, 0, 0.74);
    font-size: 13px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.04px;
  }

  .date-picker {
    padding: 4px !important;
    border-radius: 3px 3px 3px 3px !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    color: #212121;

    &:focus-visible {
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }

  .rbt-menu {
    inset: 0px auto auto -32px !important;
    width: 282px !important;
  }
  .input-group {
    flex-wrap: nowrap !important;
  }
}
