.reset-elements {
  .client-details-card {
    width: 100%;
  }

  .profile-image-56 {
    width: 56px;
    height: 56px;
  }

  .profile-image-40 {
    width: 40px;
    height: 40px;
  }

  .text-1 {
    color: #8c8c8c;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .client-btn {
    padding: 4px 6px !important;
    min-width: 57px !important;
    font-size: 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .font-weight-500 {
    font-weight: 500;
  }
  .btn {
    &.btn-icon {
      width: fit-content;
      &:active,
      &.btn-check {
        border-color: transparent !important;
      }
    }
  }
}
