.reset-elements {
  .cus-page-link {
    &:active {
      color: #0d6efd !important;
    }

    background: none !important;
    color: black !important;
    border-bottom: 0 !important;
  }

  .cus-page-item {
    &:active {
      color: #0d6efd !important;
    }

    background: none !important;
    color: black !important;
  }

  .selected-page {
    border-color: none !important;
    color: #0d6efd !important;

    .page-link {
      color: #0d6efd !important;

      &:active {
        box-shadow: none !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .border-left-0 {
    border-left: 0 !important;
  }

  .border-top-0 {
    border-top: 0 !important;
  }

  .border-right-0 {
    border-right: 0 !important;
  }

  .border-radius-0 {
    border-radius: 0 !important;
  }

  .border-top-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .text-grey {
    color: #616161 !important;
  }
  .pagination-tab {
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    left: 0;
    padding: 0px 20px 0px 36px;
  }
}
