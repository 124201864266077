.reset-elements {
  .modal-content {
    border: none;
  }

  .modal-header {
    border-bottom: none !important;
    padding: 20px 15px 36px 15px !important;
  }
  .img-left {
    background-color: #9e9e9e;
    height: 100%;
    width: 110%;
    border-radius: 5px 0px 0px 5px;
  }

  .modal-right {
    border-radius: 0px 4px 4px 0px;
  }

  .text-area {
    width: 96% !important;
    margin-left: 10px;
    height: 150px;
    border-radius: 4px !important;
    border: 1px solid #9e9e9e;
  }

  .text-input {
    border-radius: 4px;
    height: 44px;
    width: 100%;
    padding: 10px;
    border: 1px solid #9e9e9e;
  }

  .text-left-01 {
    position: absolute;
    bottom: 80px;
    color: rgba(255, 255, 255, 1);
  }

  .text-left-02 {
    position: absolute;
    bottom: 15px;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.017px;
    color: rgba(255, 255, 255, 1);
  }

  .btn-preview {
    position: relative;
    border: 1px solid #9e9e9e !important;

    .material-icons {
      position: absolute;
      right: 175px;
      bottom: 2px;
    }
  }

  .text-count {
    float: right;
  }
}
