.reset-elements {
  .alert-close-icon {
    float: right;
    font-size: 10px;
    cursor: pointer;
  }

  .info-icon {
    font-size: 20px;
  }

  .alert {
    padding: 14px !important;
    border-radius: 1px !important;
    border: none !important;
    margin-bottom: 0px !important;
    background-color: rgba(240, 185, 215, 1) !important;
    color: rgba(206, 30, 125, 1) !important;
  }

  .card-wrap {
    padding: 10px 25px 16px 14px;
  }

  .order-progress-card {
    border-width: 1px 1px 0px 1px !important;
    border-style: solid !important;
    border-color: rgba(0, 0, 0, 0.193) !important;
    border-radius: 4px 4px 0px 0px !important;
    padding: 12px !important ;
  }

  .footer-card-body {
    border: 1px solid rgba(0, 0, 0, 0.193) !important;
    border-radius: 0px 0px 4px 4px !important;
    padding: 12px !important;
  }

  .label-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: rgb(140, 140, 140);
  }

  .card-style {
    background-color: rgba(206, 30, 125, 1) !important;
    color: #ffffff !important;

    &.label-text {
      color: #ffffff !important;
    }
  }
  .card-style-footer {
    background-color: rgba(206, 30, 125, 1) !important;
    color: #ffffff !important;
    border-radius: 0px 0px 4px 4px !important;

    &.label-text {
      color: #ffffff !important;
    }
  }

  .link-text {
    color: rgba(0, 113, 235, 1);
    text-decoration: none;
    cursor: pointer;
  }
  .header-card-body {
    padding: 0px !important;
  }
}
