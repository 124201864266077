.reset-elements {
  .nav-tab {
    color: #555555 !important;
    margin-right: 5px;
    font-size: 13px !important;
    font-family: "Inter" !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 2px !important;
    text-transform: uppercase;
    margin-right: 6px;
    padding: 12px 0px 12px 2px !important;
  }

  .selected-tab {
    color: #ce1e7d !important;
    font-size: 13px !important;
    font-family: "Inter";
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 2px !important;
    text-transform: uppercase;
    position: relative;
    margin-right: 6px;
    padding: 12px 0px 12px 2px !important;
  }

  .selected-tab:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 2px !important;
    background-color: #ce1e7d;
  }

  .tab-number {
    color: #424242 !important;
    font-size: 13px !important;
    font-family: "JetBrains Mono" !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: -0.18px !important;
  }

  .selected-tab-number {
    color: #fff !important;
    font-size: 13px !important;
    font-family: "JetBrains Mono" !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: -0.18px !important;
  }

  .nav-link {
    display: flex !important;
    width: 100% !important;
    align-items: center;
    justify-content: space-between;
  }

  .tab-badge {
    padding: 1px 5px !important;
    background-color: #ce1e7d !important;
  }
}
