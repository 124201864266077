.reset-elements {
  .breadcrumb-item {
    color: #8c8c8c !important;
    font-size: 12px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 2px !important;
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: #8c8c8c;
    }
  }

  .breadcrumb-item-current {
    color: #2d2929 !important;
    font-size: 12px;
    font-family: "Inter";
    line-height: 20px;
    letter-spacing: 2px !important;
    text-transform: uppercase;
  }

  .breadcrumb {
    --bs-breadcrumb-margin-bottom: 0rem !important;
  }
  .breadcrumb > .breadcrumb-item + .breadcrumb-item:before {
    position: relative;
    content: "/" !important;
    top: -2px;
    padding-left: 0px !important;
    letter-spacing: 2px !important;
  }
}
