@import "./styles/pages/scss/app.scss";
@import "./styles/bootstrap/scss/bootstrap.scss";

.reset-elements {
  .breadcrumb-wrap {
    margin-left: 18px;
  }

  .selected {
    text-decoration: underline;
  }

  .border {
    border: 2px solid #000000 !important;
  }

  .border-light {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .card-wrap-header {
    padding: 12px 23px 12px 15px !important;
  }
  .pagination-tab {
    bottom: 72px;
    z-index: 10;
  }
}

.page-container .page-content-wrapper .footer {
  position: fixed;
  background: white;
  padding: 0px;
  left: 0px;
  padding-left: 222px;
  z-index: 10;
  .copyright {
    padding: 25px 20px;
  }
}

@media only screen and (min-width: 980px) {
  body.menu-pin .page-container .page-content-wrapper .footer {
    left: 250px;
  }
}

@media only screen and (min-width: 980px) {
  body.menu-pin .page-container .page-content-wrapper .footer {
    left: 222px;
  }
}

#react-select-2-live-region .select__menu {
  z-index: 1000;
}
