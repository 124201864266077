.reset-elements {
  .header-title {
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #212121;
  }

  .header-type {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    background-color: rgba(128, 128, 128, 0.14);
    border-radius: 4px;
    padding: 0px 4px;
  }

  .header-turn-wrap {
    width: 18px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-turn {
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #8c8c8c;
  }

  .header-state {
    text-align: right;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.16px;
    text-transform: uppercase;
    color: #616161;
  }

  .dot-style {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: rgba(189, 189, 189, 1);
  }
  .state-dot-style-pending {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: rgba(255, 189, 0, 1);
  }
  .state-dot-style-live {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: rgba(206, 30, 125, 1);
  }
  .chev-icon {
    font-size: 10px;
  }
  .calendar-icon {
    color: rgba(206, 30, 125, 1);
  }
  .state-label {
    color: #ce1e7d;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .submit-button {
    color: #ffffff;
    background-color: #ce1e7d;
  }
  .dots-vertical-wap {
    padding: 7px;
    font-size: 24px;
  }
  span {
    margin-bottom: 0px !important;
  }
}
